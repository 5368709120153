<template>
  <section class="main-container border-clear light-gray-bg">
    <div class="container">
      <div class="row">
        <div class="main col-12">
          <h1 class="page-title text-center">HİZMETLER</h1>
          <div
            class="image-box space-top style-4"
            itemscope
            itemtype="http://schema.org/Service"
          >
            <div class="row grid-space-0">
              <div class="col-lg-6">
                <div class="overlay-container">
                  <img
                    itemprop="image"
                    src="../assets/family-service-large.jpg"
                    alt
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="body">
                  <div class="pv-30 hidden-lg-down"></div>
                  <h3 itemprop="name">Aile, Çift ve İlişki Terapisi</h3>
                  <div class="separator-2"></div>
                  <p itemprop="description" class="margin-clear">
                    İlk seansa çiftin beraber katılması tercih ve tavsiye
                    edilir, Bu görüşmede; problem tanımı yeniden yapılır,
                    beklentiler ve ihtiyaçlar belirlenir, görüşme sıklığına
                    karar verilir.
                  </p>
                  <br />
                  <router-link
                    itemprop="url"
                    class="btn btn-lg btn-default-transparent btn-sm btn-animated margin-clear"
                    :to="'/hizmetler/aile-cift-iliski-danismanligi'"
                  >
                    Devamını Oku<i class="fa fa-arrow-right pl-10"></i
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
          <div
            class="image-box style-4"
            itemscope
            itemtype="http://schema.org/Service"
          >
            <div class="row grid-space-0">
              <div class="col-lg-6 order-lg-2">
                <div class="overlay-container">
                  <img
                    itemprop="image"
                    src="../assets/family-founseling-puzzle-large.gif"
                    alt
                  />
                </div>
              </div>
              <div class="col-lg-6 order-lg-1">
                <div class="body text-right">
                  <div class="pv-30 hidden-lg-down"></div>
                  <h3 itemprop="name">Çocuk Odaklı Aile Terapisi</h3>
                  <div class="separator-3"></div>
                  <p itemprop="description" class="margin-clear">
                    İlk görüşmenin Anne-Baba ve çocuk ile birlikte yapılması
                    tercih edilir, çocuk ile ilgili ayrıntılı bilgi alınarak
                    problem tanımı yeniden yapılır ve süreç hakkında bilgi
                    verilir.
                  </p>
                  <br />
                  <router-link
                    itemprop="url"
                    class="btn btn-lg btn-default-transparent btn-sm btn-animated margin-clear"
                    :to="'/hizmetler/cocuk-odakli-aile-danismanligi'"
                  >
                    Devamını Oku<i class="fa fa-arrow-right pl-10"></i
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
          <div
            class="image-box style-4"
            itemscope
            itemtype="http://schema.org/Service"
          >
            <div class="row grid-space-0">
              <div class="col-lg-6">
                <div class="overlay-container">
                  <img
                    itemprop="image"
                    src="../assets/sunkissed-backs-offset-large.png"
                    alt
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="body">
                  <div class="pv-30 hidden-lg-down"></div>
                  <h3 itemprop="name">Ergen Odaklı Aile Terapisi</h3>
                  <div class="separator-2"></div>
                  <p itemprop="description" class="margin-clear">
                    İlk görüşmenin anne-baba ve genç ile birlikte yapılması
                    tercih edilir. Aile görüşmesinde gencin doğum öncesi,
                    sonrası ve ilk çocukluk dönemiyle ilgili hikâyesi alındıktan
                    sonra, geliş nedeni anne, baba ve genç tarafından ayrı ayrı
                    alınır, problem tanımı yapılır ve süreç hakkında bilgi
                    verilir.
                  </p>
                  <br />
                  <router-link
                    itemprop="url"
                    class="btn btn-lg btn-default-transparent btn-sm btn-animated margin-clear"
                    :to="'/hizmetler/ergen-odakli-aile-danismanligi'"
                  >
                    Devamını Oku<i class="fa fa-arrow-right pl-10"></i
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
          <div
            class="image-box style-4"
            itemscope
            itemtype="http://schema.org/Service"
          >
            <div class="row grid-space-0">
              <div class="col-lg-6 order-lg-2">
                <div class="overlay-container">
                  <img
                    itemprop="image"
                    src="../assets/rsz_no-shortcuts-large.jpg"
                    alt
                  />
                </div>
              </div>
              <div class="col-lg-6 order-lg-1">
                <div class="body text-right">
                  <div class="pv-30 hidden-lg-down"></div>
                  <h3 itemprop="name">Bireysel Terapi</h3>
                  <div class="separator-3"></div>
                  <p itemprop="description" class="margin-clear">
                    İlk seanslarda, problem tanımı, ihtiyaçlar ve beklentiler
                    üzerinde durulur. görüşme sıklığı belirlenir. Tanımlanan
                    sorun uzmanlık alanı dışında kalıyorsa danışan başka bir
                    uzmana yönlendirebilir.
                  </p>
                  <br />
                  <router-link
                    itemprop="url"
                    class="btn btn-lg btn-default-transparent btn-sm btn-animated margin-clear"
                    :to="'/hizmetler/bireysel-danismanlik'"
                  >
                    Devamını Oku<i class="fa fa-arrow-right pl-10"></i
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- main end -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  metaInfo: {
    title: "Hizmetler"
  }
};
</script>

<style></style>
